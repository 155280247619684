import { safeParseJSONLocalStorage, safeParseJSONSessionStorage } from "./getBrowserStorageData";
import { setSessionStorage } from "./storageHelpers";

export const phoneConvert = (phone) => {
    if(!phone) return '';
    let converted = phone.replace(/[^0-9]/g,'');
    if (converted.length < 3 ) {
        // converted = converted;
    } else if(converted.length===3) {
        converted = `(${converted})`
    } else if (converted.length > 3 && converted.length < 6) {
        converted = `(${converted.substr(0, 3)})${converted.substr(3)}`;
    } else if (converted.length===6) {
        converted = `(${converted.substr(0, 3)})${converted.substr(3, 3)}-`;
    } else if (converted.length>6) {
        converted = `(${converted.substr(0, 3)})${converted.substr(3, 3)}-${converted.substr(6,4)}`;
    } else {
        // converted = converted;
    }
    return converted;
}

export const setUserSelectedMarketsByBrand = () => {
    let globalBrands = safeParseJSONLocalStorage('globalBrands');
    let userSelectedGlobalMarkets = globalBrands?.reduce((accu,ele)=>{
        let result = ele?.marketGroups?.reduce((acc, current) => {
            let selectedMarkets = current?.marketGroupsList?.reduce((innerAcc, innerCurrent) => {
              innerAcc.push(innerCurrent?.identity); 
              return innerAcc;
            }, []);
            let selectedURLMarkets = safeParseJSONSessionStorage('userSelectedGlobalMarkets')?.find(({brandId})=> brandId == ele?.id)?.markets;
            selectedMarkets = selectedMarkets?.filter(ele => selectedURLMarkets?.some(id => id == ele));
            acc.push(...selectedMarkets); 
            return acc;
          }, []);
        return accu.concat({brandId:ele?.id,markets: [...new Set(result)]});
    },[])
    setSessionStorage('userSelectedGlobalMarkets',JSON.stringify(userSelectedGlobalMarkets));
}

export const setUserSelectedBrandsByMarket = (selectedMarket, selectedBrands) => {
    let brands = safeParseJSONLocalStorage('myBrands');
    let selectedUserBrands = safeParseJSONSessionStorage('userSelectedBrands');
    let userSelectedBrands = selectedUserBrands?.length > 0 ? selectedUserBrands : [];
    let findIndex = userSelectedBrands?.findIndex(({marketId})=>marketId == selectedMarket);
    if(findIndex> -1){
        userSelectedBrands[findIndex].brands = selectedBrands;
    }else if(selectedBrands?.length > 0)
        userSelectedBrands.push({marketId: selectedMarket, brands: selectedBrands})
    else{
        userSelectedBrands.push({marketId: selectedMarket, brands: brands?.map(({id})=>id)})
    }
    setSessionStorage('userSelectedBrands',JSON.stringify(userSelectedBrands));
}

export const setUserSelectedMarkets = (selectedGlobalBrandId, selectedMarkets) => {
    let userSelectedGlobalMarkets = safeParseJSONSessionStorage('userSelectedGlobalMarkets');
    let findIndex = userSelectedGlobalMarkets?.findIndex(({brandId})=> brandId == selectedGlobalBrandId);
    findIndex > -1 && (userSelectedGlobalMarkets[findIndex].markets = [...new Set(selectedMarkets)]);
    setSessionStorage('userSelectedGlobalMarkets',JSON.stringify(userSelectedGlobalMarkets));
}

export const validationsForHSSMarketLabels = (item, market, zFields) => {
    let data = [];
    for (let key in item) {
        if (key !== 'pool' && key !== 'higherThan' && key !== 'lowerThan' && key !== 'inputName' && key !== 'inputId' && key !== 'date' && key !=='genpop'&& key !=='poolTotal' && key !== 'newDate' && !key.includes('Diff') && key !== 'dailyAverageSampleSize'){
            if(market === 'HSS'){
                let value = item[key];
                let zValue = zFields && zFields[key];
                if(key === 'fun') key = 'approachable';
                else if(key === 'hip') key = 'bestInClass';
                else if(key === 'intelligent') key = 'knowledgeable';
                else if(key === 'stylish') key = 'personable';
                else if(key === 'onlineGaming') key = 'SearchEngineegGoogleBing';

                data.push({ 
                    label: key, 
                    value: Number((value)?.toFixed(1)),
                    zValue: ((zValue)?.toFixed(1))
                });
            }else if(market !== 'HSS'){ 
                data.push({ 
                    label: key, 
                    value: Number((item[key])?.toFixed(1)),
                    zValue: zFields && ((zFields[key])?.toFixed(1))
                });
            }
        }                     
    }
    return data;
}

export const filterHSSMarketTrendsLabels = (market , trendsLabels) => {
    if(market === 'HSS'){
        trendsLabels = trendsLabels.map(obj=>{
            if(obj?.id === 'fun' || obj?.name === 'fun') obj.name = 'Approachable';
            else if(obj?.id === 'hip' || obj?.name === 'hip') obj.name = 'Best in Class';
            else if(obj?.id === 'intelligent' || obj?.name === 'intelligent') obj.name = 'Knowledgeable';
            else if(obj?.id === 'stylish' || obj?.name === 'stylish') obj.name = 'Personable';
            else if(obj?.id === 'onlineGaming' || obj?.name === 'onlineGaming') obj.name = 'Search Engine(e.g., Google, Bing)';
            return obj;
        })
    }
    return trendsLabels;
}

export const segmentsList = (segments) => {
    let segmentsList = segments?.reduce((acc, sr) => {
        acc.push(...sr?.segments);
        return acc;
    }, []);  
    return segmentsList;
}

export const getSelectedGlobalBrandMarketsIds = ( selectedBrand) => {
    let selectedURLMarkets = safeParseJSONSessionStorage('userSelectedGlobalMarkets')?.find(({brandId})=> brandId == selectedBrand?.id)?.markets;
    let useHSSLabels = selectedURLMarkets.every(ele=> ele?.includes('HSS'))? true : false;
    const sortedMarketAry = selectedBrand.marketGroups.reduce((acc, mg) => {
        acc.push(...mg.marketGroupsList);
        return acc;
    }, []).sort((a,b) => a.position - b.position);
    selectedURLMarkets = sortedMarketAry?.filter(ele => selectedURLMarkets?.some(id => id == ele?.identity))?.map(marketObj=> marketObj?.marketId);
    selectedURLMarkets = selectedURLMarkets?.length>0 ? selectedURLMarkets:sortedMarketAry?.map(marketObj=> marketObj?.marketId);
    const geoIds = [...new Set(selectedURLMarkets)];
    return {geoIds ,useHSSLabels};
}
